.wrapperOuter {
    position: fixed;
    background: rgba(0, 0, 0, 0.27);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
}

.wrapper {
    background: #ffffff;
    position: fixed;
    left: 20px;
    right: 60px;
    top: 20px;
    bottom: 20px;
    border-radius: 5px 0 5px 5px;
    overflow: hidden;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}
.close:hover {
    background: #ddd;
}


