.header {
    @apply bg-gray-100 rounded-md pl-5 pr-3 pb-0 pt-0 mb-5 flex items-center justify-between;
    min-height: 60px;
}

.headerMain {
    background: #EFF7FF;
}

.title {
    @apply font-medium text-lg m-0;
}
