@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    *, ::before, ::after {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
    }

    html {
        font-size: 90%;
    }
}
