@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeOut {
    animation: fadeOut 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.sidebarItem {
    padding: 10px 20px;
    cursor: pointer;
    transition: border ease 100ms;
}
.sidebarItemActive {
    background: white;
    border-left: 5px solid;
    @apply border-primary;
}
.sidebarItem:hover {
    background: white;
}
