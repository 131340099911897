.modalOuter {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal {
    display: block;
    top: 80px;
    left: 80px;
    bottom: 80px;
    right: 80px;
    outline: 0;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 40px rgb(0 0 0 / 10%);
    border: 1px solid #b1b1b1;
    max-width: 400px;
    margin: 0 auto;
    overflow-y: auto;
    width: 100%;
    max-height: calc(100% - 90px);
}
