.selector {
    @apply mx-auto table;
}

.selector > div {
    max-width: 100px;
    min-width: 100px;
    width: 100%;
}
.selector > div > div {
    display: flex;
}

.disabled,
.oldState {
    opacity: .5;
}

.oldState svg {
    display: none;
}
.oldState > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.oldState > div img {
    margin: 0 auto;
}
