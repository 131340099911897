/**
 * Table
 */
.table {
    width: 100%;
}
.table tr {
    border-bottom: 1px solid #ddd;
}
.table tbody tr:last-child {
    border-bottom: none;
}
.table th,
.table td {
    padding: 5px 4px;
}

/**
 * Table with bordered cells
 */
.tableBordered td,
.tableBordered th {
    border-right: 1px solid #ddd;
}
.tableBordered tbody tr > *:last-child,
.tableBordered thead tr > *:last-child {
    border-right: none;
}


/**
 * Div around table with outer border
 */
.tableOuterBorder {
    @apply border border-gray-200 rounded;
}

/**
 * Table with hoverable rows
 */
.tableHoverRow tbody tr:hover {
    @apply bg-gray-50;
}

/*
 * Table with rotated header
*/
.tableRotateThead th {
    vertical-align: bottom;
    text-align: center;
}
.tableRotateThead th span {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    text-align: left;
}

/**
 * Table with medium sized cells
 */
.mediumTd td {
   padding: 8px 10px;
}

/**
 * Table with medium sized cells and header
 */
.mediumTh th {
   padding: 8px 10px;
}

/**
 * Table with medium sized cells and header
 */
.mediumCell td,
.mediumCell th {
    padding: 8px 10px;
}
