.r, .y, .g {
    border: 1px solid white;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    flex-wrap: wrap;
}

.r { background: #FF595E; }
.y { background: #FFCA3A; }
.g { background: #8AC926; }

.leftColumnTitle {
    width: 120px;
    text-align: right;
}

.number {
    width: 40px;
    height: 40px;
    background: #1a1a1abd;
    color: white;
    display: flex;
    line-height: 0;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 1.4em;
}

.number2 {
    width: 22px;
    height: 22px;
    margin: 1px;
    font-size: .85em;
    border-radius: 3px;
}
.number3 {
    width: 16px;
    height: 16px;
    margin: 1px;
    font-size: .7em;
    border-radius: 2px;
}
.number4 {
    width: 16px;
    height: 16px;
    margin: 1px;
    font-size: .7em;
    border-radius: 2px;
}


.leftColumn {
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.leftColumn > div {
    border-top: 1px solid #ddd;
    height: 60px;
    line-height: 60px;
}

.bottomColumn {
    width: 60px;
    border-left: 1px solid #ddd;
    margin-top: 10px;
}
.bottomColumn:last-child {
    border-right: 1px solid #ddd;
}

.titleNumber {
    width: 60px;
    height: 60px;
    text-align: center;
    font-weight: bold;
    display: flex;
    line-height: 0;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
}

.rotate {
    vertical-align: bottom;
    text-align: center;
}
.rotate span {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    text-align: right;
}
